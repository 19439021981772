<template>
<b-card no-body>
    <b-card-body>
        <b-row>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <b-form-group
            label="Oluşturma Tarihi Başlangıç"
            label-for="dtOlusTar"
          >
            <b-form-input type="date" v-model="dtBaslangic" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
                      <b-form-group
            label="Oluşturma Tarihi Bitiş"
            label-for="dtOlusTar"
          >

            <b-form-input type="date" v-model="dtBitis" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">

                <b-form-group
                 label="Duyuru Başlığı"
                label-for="sBaslik">
                    
            <b-input 
            v-model="queryFilter"
            placeholder="Başlık Ara" />
                </b-form-group>

        </b-col>
         <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Uygulama Tipi') }}</label>
          <v-select
            v-model="uygulamaTipiFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="uygulamaTuruOptions"
            class="w-100"
            :reduce="val => val.value" />
        </b-col>
        </b-row>
    </b-card-body>
</b-card>
</template>

<script>

// Options
import StaticOptions from '@/common/options/StaticOptions'

export default {
    components: {
        
    },

    data() {
        return {
          uygulamaTuruOptions: StaticOptions.uygulamaTuruOptions,
        }
  },
  computed: {
      dtBaslangic: {
          get() {
            return this.$store.getters.getDuyuruLocalFilters.dtBaslangic
          },
          set(value) {
            this.$store.commit('setDuyuruFilter', { key: 'dtBaslangic', value })
          },
      },
      dtBitis: {
        get() {
            return this.$store.getters.getDuyuruLocalFilters.dtBitis
          },
          set(value) {
            this.$store.commit('setDuyuruFilter', { key: 'dtBitis', value })
          },
      },
      uygulamaTipiFilter: {
      get() {
        return this.$store.getters.getDuyuruLocalFilters.uygulamaTipi
      },
      set(value) {
        this.$store.commit('setDuyuruFilter', { key: 'uygulamaTipi', value })
      },
    },
    
    queryFilter: {
      get() {
        return this.$store.getters.getDuyuruLocalFilters.query
      },
      set(value) {
        this.$store.commit('setDuyuruFilter', { key: 'query', value })
      },
    },
  },
}
</script>

<style>

</style>
