import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useDuyurus() {
  const duyurusTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Uygulama', key: 'sUygulamaTuru', sortable: true },
    { label: 'Başlık', key: 'sBaslik', sortable: true },
    { label: 'Oluşturma Tarihi', key: 'dtOlusTar', sortable: true },
    { label: 'Başlangıç Tarihi', key: 'dtDuyuruBaslangic', sortable: true },
    { label: 'Bitiş Tarihi', key: 'dtDuyuruBitis', sortable: true },
    { label: 'Aksiyon', key: 'actions', class: 'text-right' },
  ]

  const perPage = ref(10)
  const toplamDuyuru = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getDuyuruLocalFilters.sortBy
    },
    set(value) {
      store.commit('setDuyuruFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getDuyuruLocalFilters.sortDesc
    },
    set(value) {
      store.commit('setDuyuruFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = duyurusTable.value ? duyurusTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamDuyuru.value,
    }
  })

  const refetchData = () => {
    duyurusTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchDuyurus = () => {
    const { dtBaslangic, dtBitis } = store.getters.getDuyuruLocalFilters;
    const query = { dtBaslangic, dtBitis }
    store.dispatch('fetchDuyurus', query)
  }

  const getDuyurus = computed(() => {
    const duyurus = store.getters.getDuyurus
    toplamDuyuru.value = duyurus.length
    return duyurus
  })

  onMounted(fetchDuyurus)

  return {
    fetchDuyurus,
    getDuyurus,
    tableColumns,
    perPage,
    currentPage,
    toplamDuyuru,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    duyurusTable,
  }
}
