<template>
  <div>
    <!-- Buttons -->
    <crud-button :onClickList="fetchDuyurus" :onClickAddNew="addNewDuyuru" />
    <!-- Filters -->
    <duyuru-filters />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("tane göster") }}</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="duyurusTable"
        class="position-relative"
        :items="getDuyurus"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(sUygulamaTuru)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.sUygulamaTuruLabel
            }}</span>
          </div>
        </template>

        <!-- Column: Email -->
        <template #cell(sBaslik)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sBaslik }}</span>
          </div>
        </template>

        <!-- Column: Telefon -->
        <template #cell(dtOlusTar)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              datetolocale(data.item.dtOlusTar)
            }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(dtDuyuruBaslangic)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              datetolocale(data.item.dtDuyuruBaslangic)
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(dtDuyuruBitis)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              datetolocale(data.item.dtDuyuruBitis)
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Update -->
          <feather-icon
            :id="`user-row-${data.item.id}-edit-icon`"
            @click="
              $router.push({
                name: 'manage-duyuru-save',
                params: { id: data.item.sGuid },
              })
            "
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />

          <!-- Remove -->
          <feather-icon
            :id="`user-row-${data.item.id}-remove-icon`"
            @click="remove(data.item.sGuid)"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.of }} öğeden {{ dataMeta.from }} -
              {{ dataMeta.to }} arası gösteriliyor</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="toplamDuyuru"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { datetolocale } from '@/@core/utils/filter'
import DuyuruFilters from './DuyuruFilters.vue';
import useDuyuru from './useDuyuru'

export default {
  components: {
    DuyuruFilters,
  },
  methods: {
    datetolocale,
    addNewDuyuru() {
      this.$router.push({ name: 'manage-duyuru-save', params: { id: -1 } })
    },
    remove(sGuid) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('removeDuyuru', sGuid)
          .then(response => {
            if (response.statusCode === 200) {
               this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });
              this.fetchDuyurus();
            } else {
              AlertService.error(this, response.message)
            }
          })
      })
    },
  },
  setup() {
    const {
      fetchDuyurus,
      getDuyurus,
      tableColumns,
      perPage,
      currentPage,
      toplamDuyuru,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      duyurusTable,
    } = useDuyuru()

    return {
      fetchDuyurus,
      getDuyurus,
      tableColumns,
      perPage,
      currentPage,
      toplamDuyuru,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      duyurusTable,
    }
  },
};
</script>

<style>
</style>
